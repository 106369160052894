











import { EdgeChange, EdgeDirection } from '@/Edge';
import Vue from 'vue';

export default Vue.extend({
  props: ['edge', 'graph'],
  methods: {
    directionChanged(newDirection: EdgeDirection) {
      const before = { direction: this.edge.direction };
      const after = { direction: newDirection }
      this.$emit('action', new EdgeChange(this.edge.id, before, after, 'direction'));
    }
  },
  computed: {
    directionOptions: () => [
      EdgeDirection[EdgeDirection.Undirected],
      EdgeDirection[EdgeDirection.Directional],
      EdgeDirection[EdgeDirection.Bidirectional]
    ]
  }
});
