










import Vue from 'vue';
import NodeDetail from './NodeDetail.vue'
import EdgeDetail from './EdgeDetail.vue'

export default Vue.extend({
  components: {
    NodeDetail,
    EdgeDetail
  },
  props: ['target', 'targetType', 'graph']
});
