

























import Vue from 'vue'

export default Vue.extend({
  props: ['current-graph-name', 'graph-stack', 'historyState']
})
