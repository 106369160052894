
















import { NodeChange, UserNodeTypes } from '@/Node';
import Vue from 'vue';
import { NodeType }  from '@/Node';

export default Vue.extend({
  props: ['node'],
  methods: {
    handleTextChange(newText: string) {
      this.$emit('action', new NodeChange(this.node.id, { text: this.node.text },
        { text: newText }, `text-change[${this.node.id}`));
    },
    handleTypeChange(newType: NodeType) {
      this.$emit('action', new NodeChange(this.node.id, { type: this.node.type },
        { type: newType }, `type-change[${this.node.id}`));
    },
  },
  computed: {
    nodeTypes: () => {
      return UserNodeTypes.map(t => [t, NodeType[Number(t)]]);
    }
  }
});
